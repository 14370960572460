import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/scss/base.scss'

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
